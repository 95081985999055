import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    // faFacebook,
    faInstagram,
    faLinkedin,
    faGithub,
} from "@fortawesome/free-brands-svg-icons";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import "./App.css";
import Timer from "./components/Timer";
import Header from "./components/Header";
import NET from "vanta/dist/vanta.net.min";

import "@fontsource/vt323";
// import gcpLogo from "./logo_googleCloud.png";

function App() {
    const reference = useRef(0);
    const [vantaEffect, setVantaEffect] = useState(0);

    useEffect(() => {
        if (!vantaEffect) {
            setVantaEffect(
                NET({
                    el: reference.current,
                    mouseControls: true,
                    touchControls: true,
                    gyroControls: false,
                    minHeight: 200.0,
                    minWidth: 200.0,
                    color: 0x20c20e,
                    // backgroundColor: 0x0e081a,
                    backgroundColor: 0x231f21,
                })
            );
        }
        return () => {
            if (vantaEffect) vantaEffect.destroy();
        };
    }, [vantaEffect]);
    return (
        <div className="app">
            <div
                id="splash-screen"
                className="splash-screen"
                ref={reference}
            ></div>
            <div className="actual-text">
                <Header />
                <div className="banner-cont">
                    <h1 style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                        niteCTF 2024
                    </h1>
                </div>
                <div className="content-cont">
                    <div className="content">
                        <p>
                            niteCTF is a jeopardy style CTF for students
                            interested in cybersecurity. It is designed to help
                            students explore newer domains in cybersecurity as
                            well as help existing professionals practice their
                            skills. We will feature challenges which cover
                            various domains of cybersecurity including
                            cryptography, hardware security, forensics, web
                            exploitation, pwn and more!
                        </p>
                        <p>
                            Prizes:
                            <br />
                            1st place - $ 500
                            <br />
                            2nd place - $ 300
                            <br />
                            3rd place - $ 200
                        </p>
                        {/* <p>Registrations opening soon!</p> */}
                        {/* <p>Registrations are open!</p> */}
                        <p>
                            <h3>
                                <Timer />
                            </h3>
                        </p>
                        <p>
                            {/* <a
                                href="https://discord.gg/"
                                class="discord-button"
                            >
                                Join Our Discord
                            </a> */}
                            {/* &nbsp; */}
                            <a
                                href="https://play.nitectf2024.live"
                                rel="noopener noreferrer"
                                class="discord-button"
                                style={{ fontWeight: "bold" }}
                                // class="event-button"
                                target="_blank"
                            >
                                Click here to register
                            </a>
                            {/* <br></br>&nbsp; */}
                            <br></br>
                            {/* <a href="https://discord.gg/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faDiscord}/></a>&nbsp; */}
                            <br></br>
                            <a
                                href="https://ctftime.org/team/62713"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faFlag} />
                            </a>
                            &nbsp;
                            <a
                                href="https://www.instagram.com/cryptonite_mit/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                            &nbsp;
                            <a
                                href="https://github.com/Cryptonite-MIT"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faGithub} />
                            </a>
                            &nbsp;
                            <a
                                href="https://www.linkedin.com/company/cryptonite-mit"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                            &nbsp;
                            {/* <a
                                href="https://www.facebook.com/cryptonitemanipal/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                            &nbsp; */}
                        </p>
                        <p>
                            <small>
                                Infra sponsored by{" "}
                                <a
                                    href="https://goo.gle/ctfsponsorship"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    goo.gle/ctfsponsorship
                                    {/* <img
                                        className="content-google-cloud-logo"
                                        src={gcpLogo}
                                        alt="Google Cloud"
                                    ></img> */}
                                </a>
                            </small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
